<div class="bg-white border-radius-6 mb-12 p-x-20 p-y-12" *ngIf="isClient || (!isClient && !isSuperAdmin)">
  <div class="d-flex align-items-center justify-content-between" [ngClass]="{ 'h-36': isEmpty(accountDetails?.plan) && isSuperAdmin && isClient }">
    <h3 class="page-title font-Jakarta">{{ 'ContractsAdmin' | i18next }}</h3>
    <button
      color="secondary"
      class="medium"
      mat-stroked-button
      matTooltip="{{ 'Edit Contract' | i18next }}"
      matTooltipPosition="above"
      *ngIf="accountDetails?.contract?.length && isSuperAdmin && !isLoading"
      (click)="openAddEditDrawer(isClient ? accountTypes.Contract : accountTypes.FirmContract)"
    >
      {{ 'Edit' | i18next }}
    </button>
  </div>
</div>
<div
  class="contracts-container bg-white border-radius-6"
  [ngClass]="{ client: isClient, firm: !isClient, 'super-admin': isSuperAdmin, 'p-x-20 p-y-12': isClient || (!isClient && !isSuperAdmin) }"
>
  <ng-container *ngIf="isLoading; else firmContainerRef">
    <div class="d-flex bg-white contracts-container border-radius-6 p-x-20 py-2 h-100">
      <div class="flex-1">
        <div class="mb-3" *ngFor="let i of [1, 2]">
          <p class="f-12 f-w-500 l-h-16 txt-grey-secondary7 skeleton-loader skeleton-title w-100 h-auto">{{ 'Type' | i18next }}</p>
          <p class="f-14 f-w-600 l-h-20 skeleton-loader skeleton-title w-100 h-auto">{{ 'Subscription' | i18next }}</p>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #firmContainerRef>
    <ng-container *ngIf="accountDetails?.contract?.length; else emptyContractContainer">
      <div>
        <p class="p-sub-heading mb-12">{{ 'Consource Global Network MSA' | i18next }}</p>
        <div class="align-items-center d-flex justify-content-between text-truncate w-420px file-card">
          <p class="file-name flex-one text-truncate">{{ accountDetails.contract[0]?.documentName }}</p>
          <ng-container
            *ngIf="isDownloadable(accountDetails.contract[0]?.documentKey) && isPreviewAvailable(accountDetails.contract[0]?.documentKey); else singleButtonRef"
          >
            <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions" [matMenuTriggerFor]="menu2">more_vert</mat-icon>
            <mat-menu #menu2="matMenu">
              <button
                mat-menu-item
                *ngIf="accountDetails.contract[0]?.documentKey && isDownloadable(accountDetails.contract[0]?.documentKey)"
                (click)="downloadDocument(accountDetails.contract[0]?.documentKey, accountDetails.contract[0]?.documentName)"
              >
                <mat-icon>download</mat-icon> {{ 'Download' | i18next }}
              </button>
              <button
                mat-menu-item
                *ngIf="accountDetails.contract[0]?.documentKey && isPreviewAvailable(accountDetails.contract[0]?.documentKey)"
                (click)="viewDocument(accountDetails.contract[0]?.documentKey)"
              >
                <mat-icon>visibility</mat-icon> View
              </button>
            </mat-menu>
          </ng-container>
          <ng-template #singleButtonRef>
            <button
              type="button"
              mat-stroked-button
              class="small ml-2"
              color="secondary"
              *ngIf="accountDetails.contract[0]?.documentKey && isDownloadable(accountDetails.contract[0]?.documentKey)"
              (click)="downloadDocument(accountDetails.contract[0]?.documentKey, accountDetails.contract[0]?.documentName)"
            >
              {{ 'Download' | i18next }}
            </button>
            <button
              type="button"
              mat-stroked-button
              class="small ml-2"
              color="primary"
              *ngIf="accountDetails.contract[0]?.documentKey && isPreviewAvailable(accountDetails.contract[0]?.documentKey)"
              (click)="viewDocument(accountDetails.contract[0]?.documentKey)"
            >
              {{ 'View' | i18next }}
            </button>
          </ng-template>
        </div>
      </div>

      <div class="m-t-20">
        <p class="p-sub-heading mb-12">{{ 'Consource Marketplace Contract' | i18next }}</p>
        <div class="align-items-center d-flex justify-content-between text-truncate w-420px file-card">
          <p class="file-name flex-one text-truncate">{{ accountDetails.contract[1]?.documentName }}</p>
          <ng-container
            *ngIf="
              isDownloadable(accountDetails.contract[1]?.documentKey) && isPreviewAvailable(accountDetails.contract[1]?.documentKey);
              else singleButtonRef1
            "
          >
            <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions" [matMenuTriggerFor]="menu1">more_vert</mat-icon>
            <mat-menu #menu1="matMenu">
              <button
                mat-menu-item
                *ngIf="accountDetails.contract[1]?.documentKey && isDownloadable(accountDetails.contract[1]?.documentKey)"
                (click)="downloadDocument(accountDetails.contract[1]?.documentKey, accountDetails.contract[1]?.documentName)"
              >
                <mat-icon>download</mat-icon> {{ 'Download' | i18next }}
              </button>
              <button
                mat-menu-item
                *ngIf="accountDetails.contract[1]?.documentKey && isPreviewAvailable(accountDetails.contract[1]?.documentKey)"
                (click)="viewDocument(accountDetails.contract[1]?.documentKey)"
              >
                <mat-icon>visibility</mat-icon> View
              </button>
            </mat-menu>
          </ng-container>
          <ng-template #singleButtonRef1>
            <button
              type="button"
              mat-stroked-button
              class="small ml-2"
              color="secondary"
              *ngIf="accountDetails.contract[1]?.documentKey && isDownloadable(accountDetails.contract[1]?.documentKey)"
              (click)="downloadDocument(accountDetails.contract[1]?.documentKey, accountDetails.contract[1]?.documentName)"
            >
              {{ 'Download' | i18next }}
            </button>
            <button
              type="button"
              mat-stroked-button
              class="small ml-2"
              color="primary"
              *ngIf="accountDetails.contract[1]?.documentKey && isPreviewAvailable(accountDetails.contract[1]?.documentKey)"
              (click)="viewDocument(accountDetails.contract[1]?.documentKey)"
            >
              {{ 'View' | i18next }}
            </button>
          </ng-template>
        </div>
      </div>
      
      <div class="m-t-20">
        <p class="p-sub-heading mb-12">{{ 'Consource Marketplace Status' | i18next }}</p>
        <div *ngIf="accountDetails?.isOptedForMarketPlace; else showNotSubscribed">
          <span class="tag tag-approved">{{ 'Subscribed' | i18next }}</span>
        </div>
        <ng-template #showNotSubscribed>
          <span class="tag tag-rejected" *ngIf="isSuperAdmin; else showSendBtn">{{ 'Not Subscribed' | i18next }}</span>
          <ng-template #showSendBtn>
            <button mat-flat-button color="primary" (click)="notifySuperAdmin()">
              Request Activation
            </button>
          </ng-template>
        </ng-template>
      </div>
    </ng-container>

    <ng-template #emptyContractContainer>
      <div class="align-items-center d-flex empty-container h-100 justify-content-center">
        <div class="p-3 d-flex align-items-center justify-content-center flex-column">
          <img class="vector-image" [src]="notFoundImage" alt="no data image" />
          <p class="nodata-title font-Jakarta m-t-20">{{ 'No Records' | i18next }}</p>
          <p class="nodata-summary mw-400 m-t-12">{{ "SORRY! There Isn't Any Records To Display!" | i18next }}</p>
          <button
            color="secondary"
            type="button"
            class="medium mt-2"
            mat-stroked-button
            [hidden]="!isSuperAdmin"
            (click)="openAddEditDrawer(isClient ? accountTypes.Contract : accountTypes.FirmContract)"
          >
            {{ 'Add Contract' | i18next }}
          </button>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>

<ng-template #emptyData>
  <p class="f-14 f-w-600 l-h-20">-</p>
</ng-template>
